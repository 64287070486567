@import '../../shared/ui/mixins';

.footer {
    background-color: black;
    width: 100%;

    .container {
        align-items: center;
        color: white;
        display: flex;
        height: 5rem;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            height: 10rem;
        }

        .logo {
            margin-left: 0.375rem;
            margin-right: auto;

            .copyright {
                margin-bottom: 0.25rem;
                margin-top: 0.25rem;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 1rem;
                    margin-top: 1rem;
                }
            }

            .img {
                color: #ffd600;

                svg {
                    height: 2rem;
                    width: auto;

                    @include media-breakpoint-up(lg) {
                        height: 3rem;
                    }
                }
            }
        }

        .item {
            color: rgba(white, 0.3);
            font-size: 2.125rem;
            margin: 0.375rem 0.625rem;

            &:hover {
                color: rgba(white, 0.9);
            }
        }
    }
}
