@keyframes from-left {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes from-right {
    from {
        opacity: 0;
        transform: translateX(100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes from-up {
    from {
        opacity: 0;
        transform: translatey(-100px);
    }

    to {
        opacity: 1;
        transform: translatey(0);
    }
}

@keyframes from-down {
    from {
        opacity: 0;
        transform: translatey(100px);
    }

    to {
        opacity: 1;
        transform: translatey(0);
    }
}
