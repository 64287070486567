.button {
    border-color: transparent;
    border-radius: 100px;
    border-style: solid;
    color: black;
    cursor: pointer;
    display: inline-block;
    // min-width: 10rem;
    font-weight: 700;
    opacity: 1;
    box-sizing: border-box;
    transition: all 300ms ease-in-out;

    &:focus {
        outline: none;
    }

    &:hover {
        background: black;
        color: white;
    }

    &.alert {
        background: #ffd600;
        color: black;
    }

    &.active {
        &.variant-info {
            $color: #0aa0d2;

            &.outline {
                background: #bae7f7;
                border-color: $color;
            }
        }
    }

    &.outline {
        background: transparent;
        border-color: #ffd600;

        &:hover {
            background: #ffd600;
            color: #000000;
        }
    }

    &.size-sm {
        border-width: 0.125rem;
        font-size: 0.875rem;
        font-weight: 600;
        // min-width: 10rem;
        padding: 0.5rem 0.875rem;
    }

    &.size-md {
        border-width: 0.125rem;
        font-size: 1rem;
        // min-width: 10rem;
        padding: 0.625rem 1.25rem;
    }

    &.size-lg {
        border-width: 0.125rem;
        font-size: 1.25rem;
        padding: 1rem 2.5rem;
    }

    &.variant-flat {
        $color: rgba(black, 0.4);
        background-color: $color;
        border-color: transparent;
        color: white;

        &.outline {
            background: white;
            border-color: $color;
            color: $color;
        }
    }

    &.variant-info {
        $color: #0aa0d2;

        background: $color;
        color: white;

        &.outline {
            background: white;
            border-color: $color;
            color: black;

            &:hover {
                background: $color;
                color: white;
            }
        }
    }

    &.variant-link {
        $color: #0aa0d2;

        background-color: transparent;
        color: $color;
        font-weight: 400;

        &.outline {
            background: transparent;
            border-color: $color;
            color: $color;

            &:hover {
                background: $color;
                color: white;
            }
        }
    }

    &.variant-primary {
        $color: #ffd600;
        background: $color;

        &:hover {
            background: black;
        }

        &.outline {
            background: transparent;
            border-color: $color;

            &:hover {
                background: $color;
                color: #000000;
            }
        }
    }

    &.variant-secondary {
        $color: #ffd600;
        background: black;
        color: $color;

        &:hover {
            background: $color;
        }

        &.outline {
            background: transparent;
            border-color: $color;

            &:hover {
                background: black;
                color: $color;
            }
        }
    }

    &.variant-danger {
        $color: #e50000;
        background: $color;

        &:hover {
            background: black;
        }

        &.outline {
            background: transparent;
            border-color: $color;
            color: $color;

            &:hover {
                background: $color;
                color: white;
            }
        }
    }

    &.variant-warning {
        $color: #ff6f00;
        background: $color;

        &:hover {
            background: black;
        }

        &.outline {
            background: transparent;
            border-color: $color;
            color: $color;

            &:hover {
                background: $color;
                color: white;
            }
        }
    }
}
