.navbar {
    background: #ffd600;
    width: 100%;

    .container {
        color: black;
        display: flex;
        align-items: center;

        .content {
            display: flex;
            align-items: center;
            width: 100%;

            .links {
                display: flex;
                margin-right: auto;

                :global .button {
                    margin-left: 0.75rem;
                }
            }
        }

        .login {
            margin-left: auto;
        }
    }
}
