.form {
    background-color: #ffd600;
    color: black;

    font-weight: bold;

    margin: 24px 12px;
    padding: 16px 18px;

    border-radius: 24px;

    h2 {
        mix-blend-mode: normal !important;
        font-weight: bold;
        text-align: center;
        font-size: 1.4rem;

        @media screen and (min-width: 1172px) {
            display: none;
        }
    }

    button {
        width: 100%;

        &:hover {
            border: 2px solid black;
            color: black;
        }
    }

    @media screen and (min-width: 1172px) {
        margin: 180px 0 0 5vw;
        min-width: 30vw;
        padding: 48px 60px 36px;
        font-size: 1.8rem;
        font-weight: normal;

        form {
            label {
                font-size: 1.4rem;
            }

            input {
                font-size: 1.2rem;
                height: 42px;
                line-height: 42px;
                margin-bottom: 24px;
            }
        }

        button {
            height: 72px;
            margin-top: 24px;
        }
    }
}

.thanks {
    font-size: 75%;

    margin-top: 48px;
}

.information {
    margin-top: 36px;
    padding: 12px 18px;

    background-color: #ffe353;
    text-align: center;

    button {
        border-color: black !important;
    }

    @media screen and (min-width: 1172px) {
        margin-top: 90px;
    }
}
