.nextsteps {
    background-color: white;

    text-align: center;

    h2 {
        padding-bottom: 24px;
    }

    margin: 16px;
    padding: 16px;
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media screen and (min-width: 1172px) {
        text-align: left;
        padding: 72px 36px 24px;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        margin: 90px 72px;

        h2 {
            padding-bottom: 0;
            padding-right: 90px;
            font-size: 4rem;
            width: 12rem;
        }
    }
}

.circle {
    background-color: lightblue;
    border-radius: 50%;

    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: auto;
    margin-bottom: 36px;

    height: 270px;
    width: 270px;

    font-size: 1.2rem;

    > * {
        margin: 6px 0;
        transform: translateY(-42px);
    }

    span {
        font-weight: bold;
    }

    :last-child {
        margin: 0 12px;
    }

    @media screen and (min-width: 1172px) {
        margin: 0 24px;
        margin-bottom: 36px;
        > * {
            margin: 6px;
        }
    }
}
