@import '../../styles/animations.global.scss';

.container {
    display: flex;
    align-items: flex-start;
    min-height: calc(100vh - 15rem);
}

.navigator {
    flex: 1;
    margin-right: 12px;
    margin-top: 42px;
    padding: 0 12px;

    > * {
        margin-bottom: 12px;
        border: 1px solid rgb(65, 62, 62);

        border-radius: 25px;
        color: rgb(65, 62, 62);
    }

    animation: from-left 1s;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.content {
    flex: 4;

    animation: from-right 1s;

    &:active {
        animation: from-right 1s;
    }

    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
