@import '../../../../shared/ui/mixins';

.buttonRow {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}

.item,
.active {
    border-radius: 24px;
    text-align: center;

    padding: 24px 0;

    cursor: pointer;
}

.item {
    background-color: #f4f4f4;
    border: 1px solid #f4f4f4;

    &:hover {
        border: 1px solid #ffd600;
    }
}

.active {
    background-color: #ffd600;
    border: 1px solid #ffd600;
}

@include media-breakpoint-up(lg) {
    .root {
        padding-left: 1rem;
    }
    .buttonRow {
        grid-template-columns: 1fr 1fr;
    }
}
