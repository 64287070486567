.root {
    align-items: center;
    border-bottom: 0.125rem solid #dfdfdf;
    display: flex;
    height: 0;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    position: relative;

    span {
        background-color: #dfdfdf;
        border-radius: 50%;
        display: block;
        width: 1.25em;
        height: 1.25em;

        &.completed {
            align-items: center;
            background-color: black;
            color: white;
            display: flex;
            justify-content: center;
            text-align: center;
            svg {
                font-size: 0.75em;
            }
        }

        &.current {
            background-color: yellow;
        }
    }
}
