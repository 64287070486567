.cost {
    color: #000;
    padding: 16px 16px 0;

    @media screen and (min-width: 1172px) {
        padding: 0 32px 0 0;
    }

    h2,
    h3,
    .tour {
        font-weight: bold;
        text-align: center;
    }

    h2 {
        mix-blend-mode: normal;

        @media screen and (min-width: 1172px) {
            text-align: left;
            vertical-align: bottom;
            margin: 60px 0;
            font-size: 3rem;
        }

        @media screen and (min-width: 1172px) {
            color: black;
        }

        @media screen and (min-width: 1244px) {
            color: white;
        }
    }

    h3 {
        @media screen and (min-width: 1172px) {
            font-size: 2rem;
        }
    }
}

.card {
    background-color: black;
    color: white;

    border-radius: 24px;

    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1172px) {
        width: 45vw;
    }
}

.deliverables {
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 1172px) {
        flex-direction: row;
        margin: 12px 30px;
    }
}

.tour {
    width: 100%;
    background-color: #ffd600;
    color: black;
    padding-bottom: 12px;

    div {
        height: 42px;
        line-height: 42px;

        :nth-child(1) {
            vertical-align: text-bottom;
        }

        :nth-child(2) {
            font-size: 2rem;
        }
    }
}

.others {
    color: #ffd600;
    padding: 6px 36px;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    border-bottom: 3px solid #ffd600;
    border-left: 3px solid #ffd600;
    border-right: 3px solid #ffd600;

    @media screen and (min-width: 1172px) {
        width: 100%;
        padding: 6px 56px;
        border-left: 0px;
        border-right: 0px;
    }

    div {
        justify-content: space-between;
        display: flex;

        @media screen and (min-width: 1172px) {
            padding: 18px 0;
            justify-content: space-around;
            font-weight: bold;
            font-size: 1.2rem;
        }
    }
}

.info {
    padding-left: 30px;

    @media screen and (min-width: 1172px) {
        margin-left: 25%;
        margin-bottom: 30px;

        font-size: 1.2rem;
        font-weight: bold;
    }
}

.thumbnail {
    width: 90%;
    margin: 0 auto;

    img {
        width: 100%;
    }
}
