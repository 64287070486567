@import '../../shared/ui/mixins';

.container {
    @include media-breakpoint-down(xs) {
        padding: 0 0.375rem;
    }
}

.card {
    * + h2 {
        margin-bottom: 30px;
    }
}

.services {
    background-color: #fff;
}

.order {
    display: flex;
    flex-direction: column;

    h2,
    h3 {
        color: yellow;
        display: flex;
        span {
            margin-left: auto;
        }
    }

    h3 {
        font-size: 125%;
    }

    h2 {
        font-size: 175%;
    }

    .line {
        font-size: 150%;
    }

    .line + .line {
        margin-top: 24px;
    }
}

.line {
    display: flex;
    justify-content: space-between;
}

.title {
    border-bottom: 1px solid rgb(191, 191, 191);
    font-size: 4rem;
    padding-bottom: 1rem;
    margin-bottom: 4rem;
}

.request {
    display: grid;
    gap: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;

    transition: all 3ms ease-in-out;

    @include media-breakpoint-up(lg) {
        grid-template-columns: 2fr 1fr;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
}

.services {
    background-color: white;
    border-radius: 1.5rem;
    margin-bottom: 2rem;
    overflow: hidden;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    :global .button {
        display: block;
        flex: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .more {
        min-width: 15rem;
        text-align: center;
    }
}

.submit {
    align-items: center;
    display: flex;
    margin-bottom: 2rem;
    margin-top: 1.25rem;

    .loader {
        margin-left: 2rem;
    }
}
