.container {
    min-height: 100vh;
    background-color: #ffd600;

    display: flex;
    flex-direction: column;

    justify-content: center;

    padding: 0 35vw;
}

.card {
    min-height: 20vh;
    border-radius: 24px;

    display: flex;
    align-content: center;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    align-content: center;

    span {
        margin-bottom: 12px;
    }
}

.logo {
    flex: 1;
    margin-left: 12px;
    display: flex;
    align-items: center;

    img {
        width: 90px;
        height: 90px;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 12px;

    > * {
        margin: 0 0 0 8px;
    }
}
