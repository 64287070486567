@import './variables';

@mixin media-breakpoint-down($breakpoint) {
    $index: index(map-keys($grid-breakpoints), $breakpoint);
    @if ($index + 1) < length($grid-breakpoints) {
        $max-width: nth(map-values($grid-breakpoints), $index + 1);
        @media screen and (max-width: ($max-width - 1)) {
            @content;
        }
    }
}

@mixin media-breakpoint-up($breakpoint) {
    $min-width: map-get($grid-breakpoints, $breakpoint);
    @media screen and (min-width: $min-width) {
        @content;
    }
}
