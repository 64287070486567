.container {
    max-width: calc(max(30vw, 480px));

    h1 {
        width: fit-content;
        text-align: center;
        margin: auto auto 18px auto;
    }
}

.stars {
    align-self: center;
}

.card {
    display: flex;
    flex-direction: column;

    margin-right: 24px;
    min-height: 600px;

    > * {
        margin-bottom: 24px;
    }

    textarea {
        height: 180px;
        overflow: hidden;

        resize: none;
    }
}

.row {
    display: flex;
    flex-direction: row;

    margin: 12px 0 0;

    > * {
        margin-right: 6px;
    }
}
