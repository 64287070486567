.form {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    position: relative;

    &.submitting::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .section {
        margin-left: 1rem;
        min-width: 100%;
    }

    :global .form-group {
        flex: 1;
        margin-left: 1rem;
    }

    .card {
        flex-basis: 14rem;
    }

    .holder,
    .name,
    .email {
        flex: 2;
        flex-basis: 26rem;
    }

    .expiration,
    .code {
        min-width: 6rem;
    }

    .city {
        flex-basis: 20rem;
    }

    .neighborhood {
        flex-basis: 18rem;
    }

    .state {
        min-width: 6rem;
    }

    .street,
    .complement {
        flex-basis: 28rem;
    }

    .number,
    .postalCode {
        min-width: 16rem;
    }

    .identity,
    .phone {
        flex-basis: 16rem;
    }
}
