.root {
    margin-top: 1rem;

    .header {
        padding: 2rem;
    }

    .content {
        background-color: white;
        padding: 2rem;
    }

    .title {
        align-items: center;
        color: #5f5f5f;
        display: flex;
        height: 100%;
        margin: 0;
        font-size: 1.25rem;
        font-weight: 500;

        justify-content: center;

        :first-child {
            margin-right: 12px;
        }
    }
}

.info {
    background-color: #f5f5f5;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    padding: 1.25rem;

    h3 {
        font-weight: normal;
        margin-bottom: 1rem;
        text-transform: capitalize;
    }

    p {
        font-size: 1.25rem;
        margin-bottom: 0;
    }
}

.wrongAddress {
    align-items: flex-start;
    display: flex;
    flex: 1;
    font-size: 0.875rem;
    justify-content: flex-start;

    button {
        background: transparent;
        border: none;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        text-decoration: underline;
        white-space: nowrap;
    }
}

.back button {
    background-color: gray;
}

.modal {
    &::before {
        background-color: rgba(black, 0.3);
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }
    > div {
        display: block !important;
        overflow-y: auto;
    }
}

.row {
    display: flex;
    justify-content: space-between;
}

.check {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
