.nav {
    background-color: #ffd600;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 8rem;
}
