.container {
    @media only screen and (max-width: 720px) {
        margin: 0 12px;
    }

    > * {
        margin-bottom: 12px;
    }
}

.title {
    color: #13be2a;
    text-align: center;
    font-size: xx-large;

    @media only screen and (max-width: 720px) {
        font-size: large;
    }

    :first-child {
        margin-right: 24px;
    }
}

.divider {
    border-bottom: 1px solid #b2b2b2;
}

.listProducts {
    box-sizing: border-box;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 15px 0;

    @media only screen and (min-width: 540px) {
        grid-template-columns: 1fr 1fr;
        gap: 14px;
    }

    @media only screen and (min-width: 720px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;
    }

    @media only screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
    }

    @media only screen and (min-width: 1124px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 18px;
    }
}

.product {
    width: 100%;
    padding: 40px 30px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #b2b2b2;
    box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
    box-sizing: border-box;
}

.productTitle {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    box-sizing: border-box;
}

.productIcon {
    max-width: 50px;
    width: 100%;
    height: auto;

    color: #0aa0d2;

    svg {
        color: #0aa0d2;
    }
}
