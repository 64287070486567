.item {
    font-size: 14px;
    line-height: 17px;

    &:before {
        content: '>';
        display: inline-block;
        margin-left: 6px;
        margin-right: 6px;
    }

    &:first-child {
        &:before {
            display: none;
        }
    }
}
