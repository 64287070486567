.heroBack {
    background-color: black;
    height: 55vh;
    width: 100%;

    position: absolute;
    z-index: -1;

    @media screen and (min-width: 1172px) {
        min-height: 700px;
        height: 70vh;
    }
}

.hero {
    color: #fff;

    margin: 0 18px;

    @media screen and (min-width: 1172px) {
        display: flex;
        margin: 0 90px;
    }

    h1,
    h2 {
        mix-blend-mode: difference;
    }

    h1 {
        margin-top: 0;
        font-size: 2.5rem;

        @media screen and (min-width: 1172px) {
            font-size: 4.5rem;
            margin-bottom: 18px;
        }
    }

    h2 {
        text-align: left;
        font-weight: 400;

        font-size: 1.2rem;

        @media screen and (min-width: 1172px) {
            font-size: 1.8rem;
            padding-bottom: 36px;
        }
    }
}
