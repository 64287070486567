.card {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 180px auto;

    width: fit-content;

    padding: 0 90px 72px;

    gap: 36px;

    span {
        font-size: 150%;
    }
}
