.content {
    display: flex;

    .orientation {
        width: 35%;
        .icon {
            color: #ffd600;
            margin-bottom: 0.5rem;
        }
        .title {
            color: white;
            font-size: 1.25rem;
            margin: 0.625rem 0;

            display: flex;
            gap: 12px;
            align-items: center;
        }
        .text {
            color: white;
        }
    }

    .deliverables {
        display: flex;
        flex: 1;

        .title {
            color: #000;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.25rem;
            margin: 0.25rem 0;
            text-align: left;

            display: flex;
            gap: 12px;
            align-items: center;
        }

        .photos {
            display: grid;
            flex-basis: 50%;
            gap: 0.5rem;
            grid-template-columns: 1fr 1fr 1fr;

            .photo {
                border-radius: 0.375rem;
                box-sizing: border-box;
                background: #f2f2f2;
                border: 2px solid #f2f2f2;
                cursor: pointer;
                position: relative;

                &.dragged {
                    border-color: black;
                    border-style: dashed;
                }

                &.dragging {
                    cursor: move;
                }

                img {
                    display: block;
                    object-fit: contain;
                    height: 100%;
                    width: 100%;
                }

                .remove,
                .featured {
                    background-color: rgba(black, 0.2);
                    border-radius: 0.75rem;
                    border-width: 0;
                    color: white;
                    position: absolute;
                    height: 1.5rem;
                    top: 0.5rem;
                    min-width: 1.5rem;

                    &:hover {
                        background-color: rgba(white, 0.7);
                        color: black;
                        cursor: pointer;
                    }
                }

                .remove {
                    right: 0.5rem;
                }
                .featured {
                    left: 0.5rem;

                    &.active {
                        color: black;
                        background-color: #ffd600;
                    }
                }
            }
        }
    }
}

.rowbutton {
    margin-top: 36px;
}
