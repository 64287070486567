.root {
    width: 100%;

    .header {
        align-items: center;
        display: flex;
        font-size: 1.25em;
        justify-content: space-between;

        .navigate {
            background-color: rgba(black, 0);
            border: none;
            border-radius: 50%;
            height: 2rem;
            width: 2rem;

            &:hover {
                background-color: rgba(black, 0.07);
            }

            &:focus {
                outline-width: 0;
            }
        }

        .monthName {
            text-transform: capitalize;
        }
    }

    .container {
        height: 0;
        padding-bottom: (100% / 7) * 6;
        position: relative;
        width: 100%;

        .calendar {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .month {
            display: grid;
            height: 100%;
            grid-template-columns: repeat(7, 1fr);
            grid-template-rows: repeat(6, 1fr);

            .day {
                align-items: center;
                display: flex;
                flex-basis: 14%;
                flex-grow: 1;
                justify-content: center;

                color: #ccc;
                pointer-events: none;
            }

            .selected {
                background-color: #0aa0d2 !important;
                color: white !important;
            }

            .available {
                color: black;
                cursor: pointer;
                font-weight: 500;
                pointer-events: all;
                &:hover {
                    background-color: rgba(black, 0.06);
                }
            }

            .sunday {
                color: #aaa;
                pointer-events: none;
            }

            .other {
                pointer-events: none;
            }
        }
    }
}
