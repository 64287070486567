$grid-breakpoints: (
    xxs: 0,
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1400px,
    xxl: 1800px
);

$container-max-widths: (
    xs: 540px,
    sm: 720px,
    md: 960px,
    lg: 1140px,
    xl: 1320px,
    xxl: 1700px
);
