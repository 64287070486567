.card {
    margin-bottom: 1rem;
}

.content {
    display: flex;

    .orientation {
        width: 35%;
        .icon {
            color: #ffd600;
            margin-bottom: 0.5rem;
        }
        .title {
            color: white;
            font-size: 1.25rem;
            margin: 0.625rem 0;
        }
        .text {
            color: white;
        }
    }

    .deliverables {
        flex: 1;

        .product {
            align-items: center;
            border-radius: 1rem;
            cursor: default;
            display: flex;
            margin-top: 0.125rem;
            margin-bottom: 0.125rem;
            padding: 0.5rem 0.75rem;

            &:hover {
                background-color: rgba(black, 0.05);
            }

            .icon {
                margin-right: 1rem;
            }
            .file {
                flex: 1;
            }
            .remove {
                background-color: rgba(black, 0.13);
                border: none;
                border-radius: 0.9rem;
                cursor: pointer;
                height: 1.8rem;
                width: 1.8rem;

                &:hover {
                    background-color: rgba(black, 0.6);
                    color: white;
                }
            }
        }
    }
}
