.grid {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 24px;
}

.row {
    display: flex;

    justify-content: flex-end;
}

.input {
    div {
        border-radius: 6px;
        border-color: #7f7f7f;
    }
}
