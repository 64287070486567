@import '../../../shared/ui/mixins';

.root {
    margin-top: 1rem;
    min-height: 16rem;

    .loading {
        align-items: center;
        display: flex;
        min-height: 16rem;
        justify-content: center;
    }

    .unavailable {
        align-items: center;
        display: flex;
        flex-direction: column;
        min-height: 16rem;
        justify-content: center;
    }

    .header {
        padding: 2rem;
    }

    .content {
        background-color: white;
        margin-bottom: 2rem;
        padding: 0 2rem;

        .legend {
            font-size: 0.75rem;
            margin-bottom: 1rem;
            margin-top: 0;
            text-align: center;
        }
    }

    .title {
        align-items: center;
        color: #5f5f5f;
        display: flex;
        height: 100%;
        margin: 0;
        font-size: 1.25rem;
        font-weight: 500;

        justify-content: center;

        :first-child {
            margin-right: 12px;
        }
    }

    .submit {
        background-color: #4300be;
        color: #ffffff;
    }

    .calendar {
        margin-bottom: (100% / 7);
    }

    .schedule {
        display: flex;
        flex-direction: column;
        height: 100%;

        .schedules {
            flex: 1;
        }
    }

    .feedback {
        color: red;
        opacity: 0;
        padding-left: 1rem;
        text-align: center;
        transition: opacity 200ms linear;

        &.show {
            opacity: 1;
        }
    }
}

@include media-breakpoint-up(lg) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .submitButton {
        align-self: flex-end;
    }
}
