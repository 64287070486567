.tagger {
    display: flex;
    flex-wrap: wrap;
}

.taggable {
    height: 30px;
    line-height: 30px;
    min-width: 60px;
    padding: 0 6px;
    text-align: center;
    border: 1px solid #7f7f7f;
    border-radius: 6px;

    margin: 0 6px 6px 0;

    color: #7f7f7f;
    background-color: transparent;

    &.active {
        border: 1px solid whitesmoke;
        background-color: #0aa0d2;
        color: whitesmoke;
    }
}
