@import '../variables';

.container {
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
    width: 100%;

    @each $breakpoint, $max-width in $container-max-widths {
        $breakpoint-width: map-get(
            $map: $grid-breakpoints,
            $key: $breakpoint
        );
        @media screen and (min-width: $breakpoint-width) {
            max-width: $max-width;
        }
    }
}
