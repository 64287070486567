.client {
    column-gap: 0.75rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.session {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;

    .remove {
        max-width: 4rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }
}

.add {
    align-items: center;
    background-color: transparent;
    border: none;
    color: #0aa0d2;
    cursor: pointer;
    display: flex;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    &:focus {
        outline: none;
    }

    svg {
        font-size: 1.75rem;
        margin-right: 0.25rem;
    }

    span {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1rem;
    }
}

.service {
    background-color: #f4f4f4;
    border: 2px solid #bfbfbf;
    border-radius: 10px;
    padding: 1.25rem;
    margin-bottom: 24px;

    cursor: pointer;

    &.active,
    &:hover {
        background-color: #dcf0f6;
        border: 2px solid #0aa0d0;
    }
}

.listProducts {
    box-sizing: border-box;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 15px 0;

    @media only screen and (min-width: 540px) {
        grid-template-columns: 1fr 1fr;
        gap: 14px;
    }

    @media only screen and (min-width: 720px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;
    }

    @media only screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
    }

    @media only screen and (min-width: 1124px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 18px;
    }
}

.product {
    width: 100%;
    padding: 1.25rem 1.875rem;
    // background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // border-radius: 6px;
    // border: 1px solid #b2b2b2;
    // box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
    box-sizing: border-box;
}

.productTitle {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    box-sizing: border-box;
}

.productIcon {
    max-width: 50px;
    width: 100%;
    height: auto;

    color: #0aa0d2;

    svg {
        color: #0aa0d2;
    }
}
