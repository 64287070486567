.container {
    margin-bottom: 1rem;

    .card {
        margin-top: 0.75rem;
    }
}

.filterBox {
    display: flex;
}

.row {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 960px) {
        flex-direction: column;
    }

    justify-content: space-between;
    align-content: stretch;

    .box {
        width: 100%;
        margin: 0 12px;

        > * {
            display: flex;
            flex-direction: row;

            justify-content: flex-start;
            align-items: center;
            height: 144px;
            border-radius: 0.75rem;

            &.total,
            &.scheduled,
            &.pending,
            &.delivered {
                padding: 10px;
                font-size: 1.5em;
                font-weight: bold;

                :first-child {
                    font-size: 2.5em;
                    margin-right: 12px;
                }
            }

            &.total {
                background-color: #f4f4f4;
            }

            &.scheduled {
                background-color: #e8f7fc;
                color: #0aa0d2;
            }

            &.pending {
                background-color: #fcdddd;
                color: #e50000;
            }

            &.delivered {
                background-color: #e2fee2;
                color: #00b200;
            }
        }
    }
}
