.header {
    align-items: center;
    border-bottom: 1px solid #bfbfbf;
    display: flex;
    padding: 1rem 1.5rem;
    margin-bottom: 1.25rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: -1.25rem;
    width: 100%;
}
