.service {
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    transition: all 200ms ease-in-out;

    &.active,
    &:hover {
        background-color: lightgray;
    }

    .icons {
        flex: 1;
        border-radius: 50px;
        margin: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        background-color: #fff;
        border: 1px solid lightgray;

        > * {
            width: 30px;
            height: 30px;

            color: #0aa0d2;
        }
    }

    .labels {
        flex: 3;
    }
}

.discount {
    background-color: #000;
    border-radius: 12px;
    color: #fff;
    padding: 6px 9px;
    margin-left: 12px;
}
