.form {
    .info {
        font-size: 0.75rem;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
    }

    section {
        display: grid;

        grid-template-columns: 1fr 1fr;
        column-gap: 12px;
    }
}

.check {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
